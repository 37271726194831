/* pe-eslint-dis */
export const iconUrl = (ts, night = false) => {
    let code = null;
    let suffix = night ? "n" : "d";
    if (ts == 0) code = "01" + suffix;
    if (ts == 1) code = "02" + suffix;
    if (ts == 2) code = "03" + suffix;
    if (ts == 3) code = "04";
    if (ts == 4) code = "04";
    if (ts == 5) code = "10";
    if (ts == 6) code = "40" + suffix;
    if (ts == 7) code = "09";
    if (ts == 8) code = "41" + suffix;
    if (ts == 9) code = "13";
    if (ts == 10) code = "13";
    if (ts == 11) code = "15";
    if (ts == 12) code = "06" + suffix;
    return (
      "https://cdn.jsdelivr.net/gh/YR/weather-symbols@6.0.2/dist/svg/" +
      code +
      ".svg"
    );
  };
  const table = [
    "beau",
    "ciel voilé",
    "nuageux",
    "couvert",
    "maussade",
    "pluies",
    "averses",
    "pluvieux",
    "pluies+",
    "neige",
    "pluie, neige",
    "orageux"
  ];
  export const iconTxt = ts => table[ts];
  export default { iconUrl, iconTxt };
  