import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import fr from '@/locales/vuetify'

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
      locales: { fr },
      current: 'fr',
    },
    theme: {
        themes: {
          light: {
            primary: process.env.VUE_APP_PRIMARY_COLOR || '#ffb74d',
            secondary: '#b0bec5',
            accent: process.env.VUE_APP_ACCENT_COLOR || '#142434',
            error: '#b71c1c',
          },
          dark: {
            primary: process.env.VUE_APP_PRIMARY_COLOR || '#ffb74d',
            secondary: '#b0bec5',
            accent: process.env.VUE_APP_ACCENT_COLOR || '#142434',
            error: '#b71c1c',
          },
        },
      },
});
