<template>
  <v-app id="inspire">
    <vue-pull-refresh :on-refresh="onRefresh" :config="{
      loadingLabel: 'Chargement...',
      startLabel: 'Tirer pour rafraîchir',
      errorLabel: 'Oups, une erreur est survenue',
      readyLabel: 'Relâcher pour rafraîchir',
      pullDownHeight: 100,
    }">
    <v-app-bar
      elevation="0"
      style="background: none !important"
      v-if="(token && user && $vuetify.breakpoint.mobile && $route.path == '/')" 
    >
      <v-col class="mt-6 px-0">
        <v-autocomplete
          v-model="model"
          prepend-icon="mdi-magnify"
          :items="items"
          dense
          rounded
          :loading="isLoading"
          filled
          :search-input.sync="search"
          hide-no-data
          @change="goTo"
          hide-selected
          id="v-step-1"
          item-text="search_nom"
          :placeholder="$t('city_or_cp')"
          return-object
          no-filter
        ></v-autocomplete>
      </v-col>
    </v-app-bar>
    <v-app-bar
      app
      clipped-left
      fixed
      style="flex:none!important;"
      elevate-on-scroll
      v-if="!$vuetify.breakpoint.mobile"
    >

    <v-btn  style="margin-left:64px;" icon @click="$router.go(-1)"
        ><v-icon>mdi-arrow-left</v-icon></v-btn
      >
      <v-toolbar-title
      style="flex:none!important;"
     
      class="font-weight-bold mr-3"
        v-if="!$vuetify.breakpoint.sm && !$vuetify.breakpoint.xs"
      >
      <template v-if="$airport">
        <v-icon class="mr-2">mdi-airplane</v-icon>
        {{$airport.toUpperCase()}}
      </template>  
      {{$wm.title}}
      </v-toolbar-title>
      

      <v-autocomplete v-if="$env.VUE_APP_WHITE_MARK_ID != 'monithermo' && (($env.VUE_APP_WHITE_MARK_ID != 'pm' && isLogged) | ($env.VUE_APP_WHITE_MARK_ID == 'pm'))"
        v-model="model"
        :items="items"
        :search-input.sync="search"
        :loading="isLoading"
        :placeholder="$t('city_or_cp')"
        class="mt-6"
        id="v-step-1"
        item-text="search_nom"
        dense
        filled
        hide-no-data
        hide-selected
        no-filter
        return-object
        rounded
        @change="goTo"
      ></v-autocomplete>
      <v-spacer></v-spacer>
      <template v-if="!$vuetify.breakpoint.sm && !$vuetify.breakpoint.xs">
        {{$t('hello')}},&nbsp;
        <span class="font-weight-bold" v-if="user">{{ user.user_prenom }}</span>
      </template>
      <template v-if="user.features.includes('assistance_tel')">
        <v-btn icon small @click="callModal = true" class="ml-4">
          <v-icon>mdi-phone</v-icon>
        </v-btn>
      </template>
      <v-btn small icon class="mx-4" :to="isLogged ? '/alertes/v2/list' : '/'">
        <v-badge
          v-if="nbOfAlertsUnread.length || hasCurrentAlert()"
          :content="hasCurrentAlert ? '!' : nbOfAlertsUnread.length || '0'"
          small
          color="error"
          overlap
        >
          <v-icon>mdi-bell</v-icon>
        </v-badge>
        <v-icon v-else>mdi-bell</v-icon>
      </v-btn>
      <v-btn small icon class="mx-4" v-if="isLogged" @click="logout()">
        <v-icon color="error">mdi-logout</v-icon>
      </v-btn>
      <v-btn small icon class="mx-4" v-else to="/login">
        <v-icon>mdi-login</v-icon>
      </v-btn>
      
      <template v-if="user.features.find((map) => map == 'admin')">
        <v-btn icon small :to="`/admin`"
          ><v-icon>mdi-account-cowboy-hat</v-icon></v-btn
        >
      </template>
    </v-app-bar>

    <v-dialog v-model="callModal" max-width="500">
      <v-card>
        <v-card-title class="headline">
          <v-icon>mdi-phone</v-icon>
          <span class="ml-2">Contactez-nous</span>
        </v-card-title>
        <v-card-text>

          <v-row>
            <v-col cols="12">
              <v-btn
                block
                color="info"
                class="my-4"
                href="tel:+33535540044"
                large
                >
                <v-icon large>mdi-phone</v-icon>
                <h1>05 35 54 00 44</h1>
                </v-btn
              >
            </v-col>
          </v-row>

          <v-alert type="info" icon="mdi-information" border="left">
            Attention, l’utilisation de ce numéro d’assistance météo peut générer des coûts supplémentaires. Voyez avec votre gestionnaire pour plus de précisions
          </v-alert>
        </v-card-text>
      </v-card>
    </v-dialog>


    <v-navigation-drawer
      touchless
      style="z-index: 100000;"
      v-if="!$vuetify.breakpoint.mobile && user"
      class="menu accent lighten-2"
      floating
      app
      expand-on-hover
    >
    <div 
      id="v-step-0">
      <v-list>
        <template v-for="(item, i) in menu">
          <template v-if="($env.VUE_APP_WHITE_MARK_ID && item.dedicated ? item.dedicated.includes($env.VUE_APP_WHITE_MARK_ID) : false || !$env.VUE_APP_WHITE_MARK_ID) || (!isLogged && !item.logged && !token)">
          <v-list-group :key="i" v-if="item.menu.length > 1">
            <template v-slot:activator>
              <v-list-item-icon>
                <v-icon>{{item.icon}}</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="white--text">
                <b>{{ item.label }}</b></v-list-item-title
              >
            </template>

            <template v-for="(sub, i) in item.menu">
              <template v-if="$env.VUE_APP_WHITE_MARK_ID && sub.dedicated ? sub.dedicated.includes($env.VUE_APP_WHITE_MARK_ID) : false || !$env.VUE_APP_WHITE_MARK_ID">
                
              <template
                v-if="item.subLabel === 'sites' || item.subLabel === 'services'"
              >
                <v-list-item
                  v-if="
                    !sub.rules || user.features.find((map) => map == sub.rules)
                  "
                  exact
                  :href="sub.to"
                  :key="i"
                  target="_blank"
                >
                  <v-list-item-title class="white--text"
                    v-html="`- ${sub.label}`"
                  
                  ></v-list-item-title>
                </v-list-item>
              </template>
              <template v-else>
                <v-list-item exact :to="sub.to" :key="i" link>
                  <v-list-item-icon v-if="sub.icon"
                    ><v-icon>{{ sub.icon }}</v-icon></v-list-item-icon
                  >
                  <v-list-item-title
                    class="white--text"
                    ><span v-html="sub.label"/></v-list-item-title>
                </v-list-item>
              </template>

            </template>
            </template>
          </v-list-group>
          <v-list-item
            v-else-if="item.menu[0]"
            :key="`item-menu-${i}`"
            link
            :to="item.menu[0].to"
            :href="item.menu[0].href"
          >
          <v-list-item-icon>
                <v-icon>{{item.icon}}</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="white--text">

                <span 
                  ><b>{{ item.menu[0].label }}</b></span>
              </v-list-item-title>
          </v-list-item>
        </template>
      </template>
      <v-list-item v-if="isLogged" link @click="logout">
        <v-list-item-icon>
          <v-icon color="error">mdi-logout</v-icon>
        </v-list-item-icon>
        <v-list-item-title>
          <span class="red--text font-weight-bold">{{
            $t('logout')
          }}</span>
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </div>
  </v-navigation-drawer>
    <v-main class="pb-16" :style="!$vuetify.breakpoint.mobile && user ? 'padding: 64px 0px 0px 64px!important;height:100vh;':'height:100vh;'">
      <keep-alive>
        <router-view :key="$route.path"/>
      </keep-alive>
    </v-main>
    <v-overlay color="white" v-if="mainloading" class="text-center">
      <v-progress-circular color="primary" indeterminate></v-progress-circular>
      <h3 class="primary--text">{{$t('load_in_progress')}}</h3>
    </v-overlay>
    <v-dialog
      :width="$vuetify.breakpoint.mobile ? '100vw' : '50vw'"
      v-model="news"
    >
      <v-card>
        <v-card-text>
          <v-col>
            <iframe
              width="100%"
              :height="$vuetify.breakpoint.mobile ? '200px' : '500px'"
              src="https://www.youtube.com/embed/4_9wSfhtwWI"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
            <h1>What's news on MyPrevimeteo today ?</h1>
            <ul class="mt-3">
              <li>Features improvments</li>
              <li>Features improvments</li>
              <li>Features improvments</li>
              <li>Features improvments</li>
              <li>Features improvments</li>
            </ul>
          </v-col>
        </v-card-text>
        <v-card-actions>
          <v-btn block elevation="0" color="primary">Alright</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-bottom-navigation
      fixed
      app
      v-if="$vuetify.breakpoint.mobile && user"
    >
      <v-btn exact small to="/" value="recent">
        <span>{{$t('menu_forecasts')}}</span>

        <v-icon>mdi-weather-sunny</v-icon>
      </v-btn>


      <v-btn exact small to="/stations" value="favorites" v-if="isLogged">
        <span>{{$t('menu_stations')}}</span>

        <v-icon>mdi-wifi</v-icon>
      </v-btn>
      <v-btn exact small to="/maps/radar" value="nearby">
        <span>{{$t('menu_map')}}</span>

        <v-icon>mdi-map</v-icon>
      </v-btn>

      <v-btn exact to="/alertes/v2/list" value="alerts" v-if="isLogged">
        <span>{{$t('menu_alerts')}}</span>
        <v-badge
          v-if="nbOfAlertsUnread.length || hasCurrentAlert()"
          :content="hasCurrentAlert() ? '!' : nbOfAlertsUnread.length || '0'"
          small
          color="error"
          overlap
        >
          <v-icon>mdi-bell</v-icon>
        </v-badge>
        <v-icon v-else> mdi-bell </v-icon>
      </v-btn>
      <v-btn exact small to="/profil" value="profile" v-if="isLogged">
        <span>{{$t('menu_profil')}}</span>

        <v-icon>mdi-account</v-icon>
      </v-btn>
    </v-bottom-navigation>
    <template v-if="$route.query">
    <v-dialog v-model="$route.query.haveAccess">
      <v-card>
        <v-card-text>
          <v-col class="text-center">
            <v-icon x-large color="success">mdi-check</v-icon>
              <h1 class="success--text my-6"> Merci pour votre achat</h1>
              <v-btn rounded block color="primary" class="py-4" to="/maps/radar">Découvrir</v-btn>
          </v-col>
        </v-card-text>
      </v-card>
    </v-dialog>
    </template>
   <v-tour name="myTour" :options="myOptions" :steps="steps">
   </v-tour>
  </vue-pull-refresh>
  </v-app>

</template>

<script>
import { Capacitor } from '@capacitor/core';
import { LocalNotifications } from '@capacitor/local-notifications';
import { PushNotifications } from '@capacitor/push-notifications';
import * as md5 from 'md5';
import moment from 'moment';
import { mapState } from 'vuex';

import api from '@/api';

export default {
  computed: {
    steps()
    {
      return [
         !this.$vuetify.breakpoint.mobile && {
            target: '#v-step-0',  // We're using document.querySelector() under the hood
            header: {
              title: `Présentation ${this.$wm.title}`,
            },
            content: `👈 Retrouvez tous vos menus ici`,
             params: {
              placement: 'right' // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
            },
          },
          {
            target: '#v-step-1',  // We're using document.querySelector() under the hood
            header: {
              title: `Présentation ${this.$wm.title}`,
            },
            content: `Si vous n'avez pas de favoris vous devez entrer le nom d'une ville ou son code postal`,
             params: {
              placement: 'bottom' // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
            },
          },
        ]
        },
    ...mapState('loader', ['loading']),
    ...mapState(["user", "token", "routerLoading", "mainloading", "alerts", "isLogged", "airport"]),
    nbOfAlertsUnread() {
      if (this.alerts.length == 0) return 0;
      return this.alerts.filter((map) => map.lu == "0");
    },
    fields() {
      if (!this.model) return [];

      return Object.keys(this.model).map((key) => {
        return {
          key,
          value: this.model[key] || "n/a",
        };
      });
    },

    items() {
      return this.entries.map((chunk) =>
        chunk.cp
          ? {
              ...chunk,
              search_nom: `${chunk.nom} (${chunk.cp.slice(0, 2)}) ${chunk.iso}`,
            }
          : { ...chunk, search_nom: `${chunk.nom} ${chunk.iso}` }
      );
    },

    menu() {
      if (!this.user) return [];
      if (!this.user.favoris) return [];

      let favoris = this.user.favoris;
      let cities_menu = [];
      let stations = this.user.stations;
      let stations_menu = [];

      stations_menu.push({
        to: `/stations/create`,
        dedicated:["pm", "acmg"],
        label: this.$t("navbar.stations.create"),
      });

      stations_menu.push({
        to: `/stations`,
        dedicated:["pm", "acmg"],
        label: this.$t("navbar.stations.all"),
      });

      stations.forEach((element) => {
        stations_menu.push({
          dedicated:["pm", "acmg"],
          to: `/stations/${element.id_station}`,
          label: element.libelle_station,
          icon: "mdi-wifi",
        });
      });

      favoris.forEach((element) => {
        cities_menu.push({
          dedicated:["pm", "acmg"],
          to: `/forecasts/${element.id_ville}/${element.libelle_lien}/resum`,
          label: this.htmlDecode(element.libelle_lien),
        });
      });
      
      
      if (this.$env.VUE_APP_WHITE_MARK_ID == 'monithermo') {
        return [
          {
            label: this.$t("navbar.home.title"),
            menu: [{ to: "/", label: this.$t("navbar.home.dashboard") }],
          },
          {
            label: this.$t("navbar.stations.title"),
            menu: stations_menu,
          },
          {
            label: this.$t("navbar.cities"),
            menu: cities_menu,
          },
          {
            label: this.$t("navbar.alerts.title"),
            menu: [
              {
                to: "/alertes/v2/list",
                label: this.$t("navbar.alerts.all"),
                rules: "alerts",
              },
            ],
          },
          {
            to: "https://admin-one.justboil.me",
            label: this.$t("navbar.profile"),
            menu: [
              {
                to: "/profil",
                label: this.$t("my_account"),
              },
            ],
          }
          
        ];
      }
      return [
        {
          label: this.$t("navbar.home.title"),
          menu: [{ to: "/", label: this.$t("navbar.home.dashboard") }],
          icon: "mdi-home",
          dedicated:['fp',"pm", "acmg"]
        },
        //{
         //  label: this.$t("navbar.home.airport"),
          // icon: "mdi-airplane",
          // menu: [
           //  { to: "/airport", label: this.$t("navbar.home.charts"), icon: "mdi-chart-multiple", dedicated:['fp',"pm", "acmg"] },
            // { to: "/airport/alerts", label: this.$t("navbar.home.airport_alerts"), icon: "mdi-bell", dedicated:['fp',"pm", "acmg"] },
           //],
           //dedicated:['fp',"pm", "acmg"]
        //},
        {
          label: this.$t("navbar.stations.title"),
          icon: "mdi-wifi",
          logged: true,
          menu: stations_menu,
          dedicated:['fp',"pm","acmg"]
        },
        {
          label: this.$t("navbar.cities"),
          icon: "mdi-weather-cloudy",
          logged: true,
          menu: cities_menu,
          dedicated:['fp',"pm", "acmg"]
        },
        {
          label: this.$t("navbar.alerts.title"),
          icon: "mdi-bell",
          logged: true,
          dedicated:['fp',"pm","acmg"],
          menu: [
            {
              to: this.isLogged ? "/alertes/v2/list" : '/',
              label: this.$t("navbar.alerts.all"),
              rules: "alerts",
            },
          ],
        },
        {
          label: this.$t("navbar.radar.title"),
          icon: "mdi-map",
          dedicated:["fp","pm", "acmg"],
          menu: [
            {
              dedicated:["pm", "acmg"],
              to: "/maps/weathermap",
              rules: "weathermaps",
              label: this.$t("navbar.maps"),
              icon: "mdi-map",
            },
            {
              dedicated:["pm", "acmg"],
              to: "/maps/radar",
              rules: "radar_precipitations",
              label: this.$t("radar_sat"),
              icon: "mdi-weather-rainy",
            },
            {
              to: "/maps/stormwatch",
              dedicated:["fp","pm","acmg"],
              rules: "stormwatch",
              label: this.$t("navbar.stormwatch"),
              icon: "mdi-weather-lightning",
            },
            this.user.features.includes('hail') && {
              to:"/maps/hail",
              dedicated:["fp","pm","acmg"],
              rules: "hail",
              label: this.$t("navbar.hail"),
              icon: "mdi-weather-hail",
            }
          ],
        },
        {
          to: "/profil",
          icon: "mdi-account",
          logged: true,
          label: this.$t("navbar.profile"),
          dedicated:["fp","pm","acmg"],
          menu: [
            {
              to: this.isLogged ? "/profil" : '/login',
              label: this.$t("my_account"),
            },
          ],
        },
        {
          to: "mailto:contact@previmeteo.com",
          icon: "mdi-email",
          label: this.$t("navbar.contact"),
          dedicated:["fp","pm"],
          menu: [
            {
              href: "mailto:contact@previmeteo.com",
              label: this.$t("navbar.contact"),
            },
          ]
        },
        {
          label: this.$t("navbar.services.title"),
          subLabel: "services",
          logged: true,
          menu: [
            {
              to: "https://services.previmeteo.com/fr/certificat-intemperies.html?utm_source=MyPM&utm_medium=Menu%20Lateral&utm_campaign=Certificats",
              label: this.$t("navbar.services.certificates"),
            },
            {
              to: "https://services.previmeteo.com/fr/webservices-api-meteo-sites-internet.php?utm_source=MyPM&utm_medium=Menu%20Lateral&utm_campaign=API",
              label: this.$t("navbar.services.webservices"),
            },
            {
              to: "https://services.previmeteo.com/fr/abonnement-meteo-pro.php?utm_source=MyPM&utm_medium=Menu%20Lateral&utm_campaign=Abonnements",
              label: this.$t("navbar.services.subscriptions"),
            },
            {
              to: "https://services.previmeteo.com/fr/reception-alertes-meteo.php?utm_source=MyPM&utm_medium=Menu%20Lateral&utm_campaign=Alertes",
              label: this.$t("navbar.services.alerts"),
            },
          ],
        }
      ];
    },
  },

  data: () => ({
    callModal: false,
    alertsList: [],
    descriptionLimit: 60,
    drawer: true,
    entries: [],
    isLoading: false,
    model: null,
    news: false,
    search: null,
    myOptions: {
      useKeyboardNavigation: false,
      labels: {
        buttonSkip: 'Passer',
        buttonPrevious: 'Précédent',
        buttonNext: 'Suivant',
        buttonStop: 'Terminer'
      },
    },
  }),

  methods: {
    async getAirport(){
            console.log('getAirport', this.$airport)
            try{
                let response = await  api.airport.get(this.$airport)
                console.log(response)
                moment.tz.setDefault(response.timezone)
                this.$store.dispatch('setAirport', response)
            }catch(err){
                console.log(err)
            }
        },
    onRefresh() {
      console.log('refresh')
        this.$router.go();
    },  
    hasCurrentAlert() {
      if(this.alertsList.length == 0) return false;
      const result = this.alertsList[0].message.indexOf('Alerte Orage') > -1 || this.alertsList[0].message.indexOf('pluie imminente') > -1;

      if (!result) return false;
        return this.alertsList[0];
    }, 
    async getAlerts() {
      const res = await api.alert.get(this.user.id_user);
      res.map((map) => (map.date = moment(map.date).format("DD MMM HH:mm")));
      this.alertsList = res;
    },
    startTour()
    {
      this.$tours['myTour'].start()
    },
    track() {
      this.$gtag.pageview(this.$route)
    },
    md5,
    OpenChat() {
      window.$crisp.do("chat:open");
    },
    htmlDecode(input) {
      var doc = new DOMParser().parseFromString(input, "text/html");
      return doc.documentElement.textContent;
    },
    logout() {
      this.$store.dispatch('logout');
      // clear user item
      localStorage.removeItem('user');
      this.$router.push({ name: 'login' });
    },
    async setLocalNotifications() {
      const platform = Capacitor.getPlatform();

      if(!['ios', 'android'].includes(platform)) return;

      let permissionStatus = await LocalNotifications.checkPermissions();

      if (permissionStatus.receive === 'prompt') {
        permissionStatus = await LocalNotifications.requestPermissions();
      }

      if (permissionStatus.receive !== 'granted') {
        console.error('local notifications denied');
        return;
      }

      await LocalNotifications.schedule({
        notifications: [
          {
            title: "Votre Météo à Bordeaux",
            body: "Test",
            id: 1,
            schedule: {
              every: "day",
              repeats: true,
              on: { hour: "09", minute: "55" },
            },
            sound: null,
            attachments: null,
            actionTypeId: "",
            extra: null,
          },
        ],
      });
    },
    sanitize(str){
      return str.replace(/[^a-zA-Z0-9]/g, '');
    },
    goTo() {
      this.$router.push(
        `/forecasts/${this.model.geoname}/${this.sanitize(this.model.nom)}/resum`
      );
    },
    clearNotifications()
    {
      const platform = Capacitor.getPlatform();

      if(!['ios', 'android'].includes(platform)) return;

      PushNotifications.removeAllDeliveredNotifications();
    },
    async setPushNotifications() {
      const platform = Capacitor.getPlatform();

      if(!['ios', 'android'].includes(platform)) return;

      PushNotifications.removeAllDeliveredNotifications();

      let permissionStatus = await PushNotifications.checkPermissions();

      if (permissionStatus.receive === 'prompt') {
        permissionStatus = await PushNotifications.requestPermissions();
      }

      if (permissionStatus.receive !== 'granted') {
        console.error('push notifications denied');
        return;
      }

      console.log('notifications granted');

      // On success, we should be able to receive notifications
      await PushNotifications.addListener('registration', (token) => {
        api.notifications.post(this.user.id_user, `${platform}@${token.value}`).then((res) => {
          console.log('token is', token.value);
          console.log('notification registered');
          console.log(res);
        });
      });

      // Some issue with our setup and push will not work
      await PushNotifications.addListener('registrationError', (error) => {
        console.error('notification error ', error)
        // alert("Error on registration: " + JSON.stringify(error));
      });
      
      // Show us the notification payload if the app is open on our device
      await PushNotifications.addListener('pushNotificationReceived', (notification) => {
          console.log('notification received');
          // alert("Push received: " + JSON.stringify(notification));
      });
        
      // Method called when tapping on a notification
      await PushNotifications.addListener('pushNotificationActionPerformed', (notification) => {
        console.log('notification performed');
        // alert("Push action performed: " + JSON.stringify(notification));
      });

      try {
        // Register with Apple / Google to receive push via APNS/FCM
        await PushNotifications.register();
      } catch (error) {
        console.error('could not register', error);
      }
    },
  },
  async created() {
    document.title = this.$wm.title;
    const link = document.querySelector("link[rel~='icon']");

    if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
    }

    link.href = this.$wm.favico;
  },
  async mounted() {
    if(this.$airport){
      console.log('get airport', this.$airport)
      this.getAirport()
    }
    this.clearNotifications();
    setInterval(() => {
      this.clearNotifications();
    }, 1000);
    this.$i18n.locale = localStorage.getItem("lang") ? localStorage.getItem("lang") : this.$env.VUE_APP_I18N_LOCALE;
    this.track();
  },
  watch: {
    search(val) {
      this.isLoading = true;

      // Lazily load input items
      api.cities
        .search(val)
        .then((res) => {
          this.entries = res;
          this.count = res.length;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => (this.isLoading = false));
    },
    "$route"() {
      this.track();
    },
    user() {
      if (this.user.id_user) {
        this.getAlerts();

        if(!localStorage.getItem('localtour')) {
          localStorage.setItem('localtour', true);
          this.startTour();
        }

        this.setPushNotifications();
        
        if (this.$route.query.connectas) {
          api.loginAs(this.$route.query.connectas).then((login) => {
            // Store token & user informations
            this.$store.dispatch('setToken', login.token);
            api.users.get(login.user.id_user).then((user) => {
              this.$store.dispatch('setUser', user);
              if (this.$route.path !== '/') this.$router.push('/');
            });
          });
        }
      }
    },
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap");

$body-font-family: "Open Sans";
$title-font: "Open Sans";
.v-application {
  font-family: $body-font-family, sans-serif !important;
  .title {
    // To pin point specific classes of some components
    font-family: $title-font, sans-serif !important;
  }
}

.bgpm {
  background-color: #222d32 !important;
}
</style>
<style lang="scss">
body {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.menu {
  .theme--light.v-label,
  .theme--light.v-icon {
    color: rgb(255 255 255 / 60%) !important;
  }
  .theme--light.v-input input,
  .theme--light.v-input textarea {
    color: rgb(255 255 255 / 87%) !important;
  }
}

.leaflet-container {
  z-index: 0 !important;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.v-application--wrap {
  overflow: hidden;
}

.v-slider__thumb-label {
  width: 100px !important;
  transform: translateY(-20%) translateY(-12px) translateX(-50%) rotate(0) !important;
  border-radius: 5px !important;
}

.v-slider--horizontal .v-slider__thumb-label > * {
  transform: rotate(0deg) !important;
}

.crisp-client .cc-unoo {
  display: none !important;
}

.v-item-group.v-bottom-navigation .v-btn {
  min-width: 50px !important;
}


.v-card__title {
  word-break: break-word !important;
}
header.v-sheet.theme--light.elevation-0.v-toolbar.v-app-bar {
    flex: none!important;
}

.v-bottom-navigation.v-item-group.theme--light.v-bottom-navigation--fixed {
    margin: 10px;
    padding-left: 5px;
    padding-right: 5px;
    width: 95%;
    border-radius: 10px;
}

// .v-main__wrap {
//     margin-bottom: 35px;
// }

a.v-btn--active.v-btn--active.v-btn.v-btn--is-elevated.v-btn--has-bg.v-btn--router.theme--light.v-btn--active.v-size--small {
    border-radius: 20px 0px 0px 20px;
}

a.v-btn.v-btn--is-elevated.v-btn--has-bg.v-btn--router.theme--light.v-size--small, a.v-btn--active.v-btn--active.v-btn.v-btn--is-elevated.v-btn--has-bg.v-btn--router.theme--light.v-size--default {
    border-radius: 10px!important;
}

nav.menu.accent.lighten-2.v-navigation-drawer {
    border-radius: 20px;
    height: 98%!important;
    margin: 5px;
    box-shadow: 1px 1px 5px #00000057;
}

.v-menu__content.theme--light.v-menu__content--fixed.menuable__content__active.v-autocomplete__content {
    z-index: 100000!important;
}

nav.menu{
  z-index:1000000!important;
}
</style>
