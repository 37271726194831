import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import i18n from './i18n';
import moment from 'moment';
import 'moment-timezone';
import VuePullRefresh from 'vue-pull-refresh';
Vue.component('vue-pull-refresh', VuePullRefresh);
import VueTour from 'vue-tour'

import whitemark from '@/assets/whitemarker/whitemark.json';

import 'vue-tour/dist/vue-tour.css';


import { Map, TileLayer,OsmSource,ImageLayer, ImageStaticSource } from 'vuelayers';

Vue.use(Map)
Vue.use(TileLayer)
Vue.use(OsmSource)
Vue.use(ImageLayer)
Vue.use(ImageStaticSource)


console.log(process.env.VUE_APP_WHITE_MARK_ID);
Vue.prototype.$wm = whitemark[process.env.VUE_APP_WHITE_MARK_ID || 'pm']
Vue.prototype.$airport = process.env.VUE_APP_AIRPORT_ID || false;
Vue.prototype.$default_model = process.env.VUE_APP_WEATHER_MODEL || '';
document.title = Vue.prototype.$wm.title
Vue.prototype.$env = process.env
Vue.use(VueTour)

import VueGtag from "vue-gtag";

import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import axios from 'axios';

import Highcharts from "highcharts";
import Stock from "highcharts/modules/stock";
import HighchartsVue from "highcharts-vue";

Stock(Highcharts);
Vue.use(HighchartsVue);

axios.defaults.showLoader = true;
console.log('process.env.NODE_ENV', process.env.NODE_ENV)
console.log(process.env.VUE_APP_MY_API_URI)

Sentry.init({
	Vue,
	dsn: "https://7b2edb4d4e314edf9538efa54c61c589@o309368.ingest.sentry.io/6079636",
	integrations: [
	  new Integrations.BrowserTracing({
		routingInstrumentation: Sentry.vueRouterInstrumentation(router),
		tracingOrigins: ["localhost", "my.previmeteo.com", /^\//],
	  }),
	],
	tracesSampleRate: 1.0,
  });


Vue.use(VueGtag, {
  config: { id: "UA-221411-17" },
  pageTrackerUseFullPath: true
});

moment.locale('fr');

Vue.config.productionTip = false;

const checkLimit = key => {
	if (!store.state.user) return false;
	let limit = store.state.user.limits.find(map => map.key == key);
	return limit ? limit.value : false;
};

Vue.prototype.$checkLimit = checkLimit;

new Vue({
	router,
	store,
	vuetify,
	i18n,
	created() {
		axios.interceptors.request.use(
			config => {
				console.log(config.baseURL)
				if (config.showLoader) {
					store.dispatch('loader/pending');
				}
				return config;
			},
			error => {
				if (error.config.showLoader) {
					store.dispatch('loader/done');
				}
				return Promise.reject(error);
			}
		);
		axios.interceptors.response.use(
			response => {
				if (response.config.showLoader) {
					store.dispatch('loader/done');
				}
	
				return response;
			},
			error => {
				let response = error.response;
	
				if (response.config.showLoader) {
					store.dispatch('loader/done');
				}
	
				return Promise.reject(error);
			}
		)
	},
	render: h => h(App),
}).$mount('#app');
